/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import history from 'Util/History';
import { lazyComponentLoader } from 'Util/lazyComponentLoader';

import { CookieInfoType, CookieSettingsType } from '../../type/GdprCookie';
import { GDPR_COOKIES_POPUP_ID } from './GdprCookiePopup.config';

import './GdprCookiePopup.style';

export const GdprCookieGroupItem = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "gdpr" */
        '../GdprCookieGroupItem'
    ), 2
);

/** @namespace GdprCookie/Component/GdprCookiePopup/Component */
export class GdprCookiePopupComponent extends PureComponent {
    static propTypes = {
        cookieGroups: PropTypes.arrayOf(CookieInfoType),
        handleCheckedGroups: PropTypes.func.isRequired,
        handleAcceptAllGroups: PropTypes.func.isRequired,
        handleAcceptCookies: PropTypes.func.isRequired,
        handleDeclineCookies: PropTypes.func.isRequired,
        cookieSettings: PropTypes.arrayOf(CookieSettingsType),
        isCookieSet: PropTypes.bool,
        isCookieBarActive: PropTypes.bool,
        isStatic: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isPopUp: PropTypes.bool.isRequired,
        isConfirmationLoading: PropTypes.bool.isRequired
    };

    static defaultProps = {
        cookieGroups: [],
        cookieSettings: [],
        isCookieSet: false,
        isCookieBarActive: false
    };

    renderCookieGroup = (cookieGroup) => {
        const { handleCheckedGroups } = this.props;
        const {
            groupId,
            isEssential,
            name,
            description,
            cookies
        } = cookieGroup;

        return (
            <GdprCookieGroupItem
              key={ `${groupId}-${name}` }
              groupId={ groupId }
              isEssential={ isEssential }
              name={ name }
              description={ description }
              cookies={ cookies }
              handleCheckedGroups={ handleCheckedGroups }
            />
        );
    };

    renderCookieGroups() {
        const { cookieGroups } = this.props;

        if (Object.keys(cookieGroups).length === 0) {
            return null;
        }

        return cookieGroups.reduce(
            (acc, e) => [...acc, this.renderCookieGroup(e)],
            []
        );
    }

    renderDeclineButton() {
        const { handleDeclineCookies } = this.props;
        const { cookieSettings: { gdpr_cookie_decline_enabled } } = this.props;

        if (!gdpr_cookie_decline_enabled) {
            return null;
        }

        return (
            <button
              block="GdprCookiesPopup"
              elem="Decline"
              type="button"
              mix={ { block: 'Button', mods: { isHollow: true } } }
              onClick={ handleDeclineCookies }
            >
                { __('Decline Cookies') }
            </button>
        );
    }

    renderButtons() {
        const {
            handleAcceptAllGroups,
            handleAcceptCookies
        } = this.props;

        return (
            <div
              block="GdprCookiesPopup"
              elem="Buttons"
            >
                <button
                  block="GdprCookiesPopup"
                  elem="Allow"
                  type="button"
                  mix={ { block: 'Button' } }
                  onClick={ handleAcceptCookies }
                >
                    { __('Accept Cookies') }
                </button>
                { this.renderDeclineButton() }
                <button
                  block="GdprCookiesPopup"
                  elem="AllowAll"
                  type="button"
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                  onClick={ handleAcceptAllGroups }
                >
                    { __('Allow All Cookies') }
                </button>
            </div>
        );
    }

    renderActions(isGdprPage) {
        const { cookieSettings: { gdpr_cookie_bar_text } } = this.props;
        const buttons = isGdprPage ? this.renderButtons() : this.renderCloseButton();
        const gdprCookieBar = !isGdprPage ? (
            <p
              block="GdprCookiesPopup"
              elem="Description"
            >
                <Html content={ gdpr_cookie_bar_text } />
            </p>
        ) : '';

        return (
            <div
              block="GdprCookiesPopup"
              elem="Actions"
            >
                { gdprCookieBar }
                { buttons }
            </div>
        );
    }

    renderContent(isGdprPage) {
        const {
            isLoading,
            cookieGroups,
            cookieSettings
        } = this.props;

        if (!cookieGroups || !cookieSettings) {
            return null;
        }

        const cookieGroup = isGdprPage
            ? (
            <div
              block="GdprCookiesPopup"
              elem="Groups"
            >
                { this.renderCookieGroups() }
            </div>
            ) : '';

        return (
            <div
              block="GdprCookiesPopup"
              elem="Wrapper"
            >
                <Loader isLoading={ isLoading } />
                { cookieGroup }
                { this.renderActions(isGdprPage) }
            </div>
        );
    }

    renderCloseButton() {
        const { handleAcceptAllGroups, isConfirmationLoading } = this.props;

        if (isConfirmationLoading) {
            return (
                <div
                  block="GdprCookiesPopup"
                  elem="LoadButton"
                  className="Button"
                  role="button"
                  tabIndex="0"
                >
                    <Loader isLoading={ isConfirmationLoading } isButton />
                </div>
            );
        }

        return (
            <div
              block="GdprCookiesPopup"
              elem="Buttons"
            >
                <button
                  block="GdprCookiesPopup"
                  elem="Allow"
                  type="button"
                  aria-label={ __('Close') }
                  mix={ { block: 'Button' } }
                  onClick={ handleAcceptAllGroups }
                >
                    { __('Super, Thanks!') }
                </button>
            </div>
        );
    }

    render() {
        const {
            isCookieSet, isStatic, isCookieBarActive, isPopUp
        } = this.props;
        const { location: { pathname } } = history;
        // TODO make config file for cookie-settings name
        const isGdprPage = pathname.includes('cookie-settings');

        if (isGdprPage && isPopUp) {
            return null;
        }

        if (isGdprPage) {
            return (
                this.renderContent(isGdprPage)
            );
        }

        if (isCookieSet || !isCookieBarActive) {
            return null;
        }

        return (
            <Popup
              id={ GDPR_COOKIES_POPUP_ID }
              clickOutside={ false }
              isStatic={ isStatic }
              isNoTransition
              mix={ { block: 'GdprPopup' } }
            >
                { this.renderContent(isGdprPage) }
            </Popup>
        );
    }
}

export default GdprCookiePopupComponent;
