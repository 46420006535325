import { withRouter } from 'react-router-dom';

import ProductCard from 'Component/ProductCard';
import ScrollSwiper from 'Component/ScrollSwiper';
import ScrollSwiperSlide from 'Component/ScrollSwiperSlide';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductListPage as SourceProductListPage
} from 'SourceComponent/ProductListPage/ProductListPage.component';

/** @namespace Bodypwa/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceProductListPage {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    renderListPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = GRID_LAYOUT
                } = {}
            },
            isSwiper
        } = this.props;

        if (isSwiper) {
            return items.map((product, i) => (
                <ScrollSwiperSlide>
                    <ProductCard
                      product={ product }
                      // eslint-disable-next-line react/no-array-index-key
                      key={ i }
                      selectedFilters={ selectedFilters }
                      layout={ layout }
                      isSwiper
                      { ...this.containerProps() }
                    />
                </ScrollSwiperSlide>
            ));
        }

        return items.map((product, i) => (
            <ProductCard
              product={ product }
              // eslint-disable-next-line react/no-array-index-key
              key={ i }
              isPlp
              selectedFilters={ selectedFilters }
              layout={ layout }
              { ...this.containerProps() }
            />
        ));
    }

    renderPlaceholders() {
        const {
            isSwiper,
            numberOfPlaceholders, mix: {
                mods: {
                    layout = GRID_LAYOUT
                } = {}
            }
        } = this.props;

        return Array.from(
            // eslint-disable-next-line no-magic-numbers
            { length: isSwiper ? numberOfPlaceholders : 24 },
            (_, i) => (
                <ProductCard
                  isSwiper={ isSwiper }
                  key={ i }
                  product={ {} }
                  layout={ layout }
                />
            )
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    startObserving() {

    }

    renderItems() {
        const { items, isLoading } = this.props;

        if (!items.length || isLoading) {
            return this.renderPlaceholderItems();
        }

        return this.renderListPageItems();
    }

    renderContent() {
        const {
            pageNumber,
            wrapperRef,
            mix,
            isSwiper
        } = this.props;

        if (isSwiper) {
            return (
                <ScrollSwiper>
                    { this.renderItems() }
                </ScrollSwiper>
            );
        }

        return (
            <ul
              block="ProductListPage"
              mix={ { ...mix, elem: 'Page' } }
              key={ pageNumber }
              ref={ wrapperRef }
            >
                { this.renderItems() }
            </ul>
        );
    }

    renderPlaceholderItems() {
        return (
            <>
                <li
                  block="ProductListPage"
                  elem="Offset"
                  ref={ this.getPlaceholderRef() }
                />
                { this.renderPlaceholders() }
            </>
        );
    }

    render() {
        return this.renderContent();
    }
}

export default withRouter(ProductListPageComponent);
