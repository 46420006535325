/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import { Suspense } from 'react';

import FieldContainer from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import Loader from 'Component/Loader';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    Product as SourceProduct
} from 'SourceComponent/Product/Product.component';
import { lazyComponentLoader } from 'Util/lazyComponentLoader';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

export const ProductWishlistButton = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "product-additional" */
        'Component/ProductWishlistButton'
    ), 2
);

export const AddToCart = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-additional" */
    'Component/AddToCart'
), 2);
/** @namespace Bodypwa/Component/Product/Component */
export class Product extends SourceProduct {
    renderQuantityChanger() {
        const {
            quantity,
            handleInputBlur,
            maxQuantity,
            minQuantity,
            setQuantity,
            inStock,
            product: { type_id }
        } = this.props;

        if (type_id === PRODUCT_TYPE.grouped) {
            return null;
        }

        return (
            <FieldContainer
              type={ FIELD_TYPE.number }
              attr={ {
                  id: 'item_qty',
                  name: 'item_qty',
                  defaultValue: quantity,
                  minQuantity,
                  max: maxQuantity,
                  min: 0
              } }
              validationRule={ {
                  inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                  isRequired: false,
                  range: {
                      min: 0,
                      max: maxQuantity
                  }
              } }
              isDisabled={ !inStock }
              mix={ { block: this.className, elem: 'Qty' } }
              events={ { onChange: setQuantity, onBlur: handleInputBlur } }
              validateOn={ ['onChange'] }
            />
        );
    }

    renderWishlistButton() {
        const { magentoProduct } = this.props;

        if (magentoProduct.length === 0) {
            return null;
        }

        return (
            <ProductWishlistButton
              magentoProduct={ magentoProduct }
              hideWishlistButton
              mix={ {
                  block: this.className,
                  elem: 'WishListButton'
              } }
            />
        );
    }

    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            inStock,
            quantity,
            originalProduct,
            getActiveProduct
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> }>
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled={ false }
                  layout={ layout }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                  originalProduct={ originalProduct }
                />
            </Suspense>
        );
    }
}

export default Product;
