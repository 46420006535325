/* eslint-disable max-len */
import {
    AddIcon as SourceAddIcon
} from 'SourceComponent/AddIcon/AddIcon.component';

/** @namespace Bodypwa/Component/AddIcon/Component */
export class AddIconComponent extends SourceAddIcon {
    render() {
        const { isPrimary, width, height } = this.props;
        const ICON_WIDTH = 16;
        const ICON_HEIGHT = 16;

        return (

            <svg
              block="AddIcon"
              mods={ { isPrimary } }
              width={ width || ICON_WIDTH }
              height={ height || ICON_HEIGHT }
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M6.75 6.75V0H9.25V6.75H16V9.25H9.25V16H6.75V9.25H0V6.75H6.75Z" fill="#070707" />
            </svg>
        );
    }
}

export default AddIconComponent;
