/* eslint-disable max-len */
import { CloseIcon as SourceCloseIcon } from 'SourceComponent/CloseIcon/CloseIcon.component';

/** @namespace Bodypwa/Component/CloseIcon/Component */

export class CloseIconComponent extends SourceCloseIcon {
    render() {
        return (
             <svg
               block="CloseIcon"
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
             <path d="M5.76783 3.99997L12.0001 10.2322L18.2322 4.0001L20 5.76787L13.7679 12L20 18.2321L18.2321 20L12.0001 13.7678L5.76784 20L4 18.2322L10.2323 12L4.00006 5.76774L5.76783 3.99997Z" fill="#070707" />
             </svg>

        );
    }
}

export default CloseIconComponent;
