import { connect } from 'react-redux';

import {
    mapStateToProps,
    RouterContainer as SourceRouterContainer
} from 'SourceComponent/Router/Router.container';
import { updateConfigDevice } from 'Store/Config/Config.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { toggleScroll } from 'Util/Browser';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import componentLoader from 'Util/componentLoader';

export const CartDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
), 2);
export const ConfigDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Config/Config.dispatcher'
), 2);
export const WishlistDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
), 2);
export const MyAccountDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
), 2);
export const GoogleTagManagerDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '@scandipwa/gtm-new/src/store/GoogleTagManager/GoogleTagManager.dispatcher'
), 2);
export const StoresDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Stores/Stores.dispatcher'
);

export {
    mapStateToProps
};
/** @namespace Bodypwa/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    updateConfigDevice: (device) => dispatch(updateConfigDevice(device)),
    init: () => {
        ConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleCustomerDataOnInit(dispatch)
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
        GoogleTagManagerDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        StoresDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
    }
});
/** @namespace Bodypwa/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    __construct() {
        this.state = ({
            currentUrl: window.location.pathname
        });

        this.redirectFromPartialUrl();
        this.handleResize();
    }

    componentDidMount() {
        this.initializeApplication();
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps) {
        const { isLoading, updateMeta } = this.props;
        const { isLoading: prevIsLoading } = prevProps;
        const { currentUrl } = this.state;
        if (currentUrl !== window.location.pathname) {
            toggleScroll(true);
            this.setState({ currentUrl: window.location.pathname });
            // * This logic if for optinmonster
            if (window.om52150_46059) {
                try {
                    window.om52150_46059.reset();
                } catch (e) {
                    console.log(e, 'This is to catch optinmonster errors');
                }
            }

            if (!BrowserDatabase.getItem('config')) {
                this.initializeApplication();
            }
        }

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code,
                isNoHreflangs
            } = this.props;

            const { value: metaTitle = meta_title } = meta_title;

            updateMeta({
                default_title,
                title: metaTitle || default_title,
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code,
                isNoHreflangs
            });
        }
    }

    initializeApplication() {
        const { init } = this.props;
        init();
    }

    redirectFromPartialUrl() {
        const { base_link_url } = this.props;
        const { pathname: storePrefix } = new URL(base_link_url || window.location.origin);
        const { pathname } = location;

        if (storePrefix === '/') {
            return;
        }

        if (storePrefix.slice(0, -1) === pathname) {
            history.replace(storePrefix);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
