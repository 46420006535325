/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { createRef } from 'react';
import { createPortal } from 'react-dom';

import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';
import history from 'Util/History';

import './Popup.style';

/** @namespace Bodypwa/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    renderTitle() {
        const { title, checkmark } = this.props;

        if (!title) {
            return null;
        }
        const renderCheckmark = checkmark ? <span className="icon-checkmark_icon" /> : null;

        return (
            <h3 block="Popup" elem="Heading">
                { title }
                { ' ' }
                { renderCheckmark }
            </h3>
        );
    }

    hidePopupAndGoBack = () => {
        const {
            setIsClosing
        } = this.props;

        setIsClosing(true);

        setTimeout(() => {
            this.hidePopUp();
            history.goBack();
            setIsClosing(false);
        // eslint-disable-next-line no-magic-numbers
        }, 300);
    };

    onVisible() {
        const { onVisible } = this.props;

        this.freezeScroll();
        this.overlayRef.current.focus();

        window.addEventListener('popstate', this.hidePopUp);

        onVisible();
    }

    hidePopUp = () => {
        const {
            hideActiveOverlay, goToPreviousNavigationState, onClose, setIsClosing
        } = this.props;
        const isVisible = this.getIsVisible();

        if (isVisible) {
            setIsClosing(true);
            setTimeout(() => {
                this.unfreezeScroll();
                hideActiveOverlay();
                goToPreviousNavigationState();
                onClose();
                setIsClosing(false);
            // eslint-disable-next-line no-magic-numbers
            }, 300);
        }
    };

    renderCloseButton() {
        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close') }
              onClick={ this.hidePopUp }
            >
                <CloseIcon />
            </button>
        );
    }

    renderContent() {
        const {
            children, contentMix, popUpHeight, isHideHeader, headerRef
        } = this.props;
        const isVisible = this.getIsVisible();

        const style = popUpHeight ? { height: popUpHeight } : {};
        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" style={ style } mix={ contentMix }>
                    <header block="Popup" elem="Header" mods={ { isHideHeader } } ref={ headerRef || createRef() }>
                        { this.renderTitle() }
                        { this.renderCloseButton() }
                    </header>
                    { this.renderNotifications() }
                    { children }
                </div>
            </ClickOutside>
        );
    }

    renderOverlay() {
        const { overlayHtml } = this.props;
        const {
            isClosing
        } = this.props;
        const isVisible = this.getIsVisible();

        if (overlayHtml) {
            return overlayHtml;
        }

        return <div block="Popup" elem="Overlay" mods={ { isVisible, isClosing } } />;
    }

    render() {
        const {
            mix, areOtherOverlaysOpen, isClosing, isNoTransition
        } = this.props;
        const isVisible = this.getIsVisible();

        return createPortal(
            <div
              ref={ this.overlayRef }
              block="Popup"
              mods={ { isVisible, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible, isClosing } } }
            >
                { this.renderOverlay() }
                <div block="Popup" elem="Transition" mods={ { isVisible, isClosing, isNoTransition } }>
                    { this.renderContent() }
                </div>
            </div>,
            document.body
        );
    }
}

export default PopupComponent;
