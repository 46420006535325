import { PureComponent } from 'react';

/** @namespace Bodypwa/Component/RulerIcon/Component */
export class RulerIconComponent extends PureComponent {
    render() {
        return (
            <svg
              width="18"
              viewBox="0 0 20 10"
              xmlns="http://www.w3.org/2000/svg"
            >
                { /* eslint-disable-next-line max-len */ }
                <path fillRule="evenodd" clipRule="evenodd" d="M7.8 2.5H5.5V5H4.5V2.5H2.5V7.5H17.5V2.5H15.4V5H14.4V2.5H12.1V5H11.1V2.5H8.8V5H7.8V2.5ZM14.4 0H15.4H20V10H0V0H4.5H5.5H7.8H8.8H11.1H12.1H14.4Z" />
            </svg>
        );
    }
}

export default RulerIconComponent;
