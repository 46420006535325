import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import {
    FieldSelect as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';
import isMobile from 'Util/Mobile';
/** @namespace Bodypwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    renderOptions() {
        const {
            options,
            isExpanded
        } = this.props;

        const filteredOtions = options.length === 2 ? options.filter((opotion) => !opotion.isPlaceholder) : options;
        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ { isExpanded } }
            >
                { filteredOtions.map(this.renderOption) }
            </ul>
        );
    }

    renderNativeSelect() {
        const {
            setRef, attr, events, isDisabled, options, handleSelectListOptionClick
        } = this.props;

        const filteredOtions = options.length === 2 ? options.filter((opotion) => !opotion.isPlaceholder) : options;
        return (
            <select
              block="FieldSelect"
              elem="Select"
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...attr }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...events }
              onChange={ handleSelectListOptionClick }
            >
                { filteredOtions.map(this.renderNativeOption) }
            </select>
        );
    }

    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            options
        } = this.props;

        const hasOneOption = options.length === 2 && options[0].isPlaceholder;
        const chevron = hasOneOption ? '' : <ChevronIcon direction={ isExpanded && !isMobile.any() ? TOP : BOTTOM } />;
        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${ id }_wrapper` }
                  block="FieldSelect"
                  mods={ { isExpanded } }
                  onClick={ hasOneOption ? false : handleSelectExpand }
                  onKeyPress={ hasOneOption ? false : handleSelectListKeyPress }
                  role="button"
                  tabIndex="0"
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div block="FieldSelect" elem="Clickable">
                        { this.renderNativeSelect() }
                        { chevron }
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
