import CheckoutDeliveryOption from 'Component/CheckoutDeliveryOption';
import {
    CheckoutDeliveryOptions as SourceCheckoutDeliveryOptions
} from 'SourceComponent/CheckoutDeliveryOptions/CheckoutDeliveryOptions.component';

import './CheckoutDeliveryOptions.style';
/** @namespace Bodypwa/Component/CheckoutDeliveryOptions/Component */
export class CheckoutDeliveryOptionsComponent extends SourceCheckoutDeliveryOptions {
    renderDeliveryOption = (option) => {
        const {
            selectShippingMethod,
            cartData: { shipping_method: selectedMethodCode },
            selectedShippingMethod: { method_code: userSelectedMethodCode = '' }
        } = this.props;

        const { method_code } = option;

        // eslint-disable-next-line no-nested-ternary
        const isSelected = userSelectedMethodCode ? userSelectedMethodCode.includes(method_code) : false
            || selectedMethodCode ? selectedMethodCode.includes(method_code) : false;

        return (
            <CheckoutDeliveryOption
              key={ method_code }
              isSelected={ isSelected }
              option={ option }
              onClick={ selectShippingMethod }
            />
        );
    };

    renderShippingMethods() {
        const { shippingMethods } = this.props;

        if (!shippingMethods.length) {
            return this.renderNoDeliveryOptions();
        }

        return shippingMethods.map(this.renderDeliveryOption);
    }
}

export default CheckoutDeliveryOptionsComponent;
