/* eslint-disable max-len */
import {
    MinusIcon as SourceMinusIcon
} from 'SourceComponent/MinusIcon/MinusIcon.component';

/** @namespace Bodypwa/Component/MinusIcon/Component */
export class MinusIconComponent extends SourceMinusIcon {
    render() {
        const { isPrimary } = this.props;

        return (
            <svg
              block="MinusIcon"
              mods={ { isPrimary } }
              width="16"
              height="4"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              elem="About"
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M0 0.749999L16 0.75L16 3.25L-1.09278e-07 3.25L0 0.749999Z" fill="#070707" />
            </svg>
        );
    }
}

export default MinusIconComponent;
