import {
    FieldNumberContainer as SourceFieldNumberContainer
} from 'SourceComponent/FieldNumber/FieldNumber.container';

/** @namespace Bodypwa/Component/FieldNumber/Container */
export class FieldNumberContainer extends SourceFieldNumberContainer {
    handleValueChange(value) {
        const {
            events: { onChange } = {}, attr: { name } = {}
        } = this.props;

        const newValue = this.setValue(value);
        if (!newValue && name === 'item_qty') {
            return;
        }
        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    }

    handleInputChange(value) {
        this.setValue(value);
    }

    handleInputBlur(value) {
        const {
            events: { onBlur } = {}, attr: { name, value: oldValue, minQuantity } = {}
        } = this.props;
        const newValue = this.setValue(value || oldValue || minQuantity);

        if (!newValue && name === 'item_qty') {
            onBlur(value);
            return;
        }
        if (typeof onBlur === 'function') {
            onBlur(newValue);
        }
    }

    containerFunctions = {
        handleValueChange: this.handleValueChange.bind(this),
        handleInputChange: this.handleInputChange.bind(this),
        handleInputBlur: this.handleInputBlur.bind(this),
        setRef: this.setRef.bind(this)
    };
}

export default FieldNumberContainer;
