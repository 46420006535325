/* eslint-disable no-unused-vars */
import {
    FieldSelectContainer as SourceFieldSelectContainer
} from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace Bodypwa/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    handleSelectExpand() {
        if (!this.isSelectDisabled()) {
            this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
        }
    }

    handleSelectExpandedExpand() {
        const { isExpanded } = this.state;

        if (isExpanded) {
            this.handleSelectExpand();
        }
    }

    handleSelectListOptionClick(option) {
        const { events: { onChange } = {} } = this.props;
        const { value, target: { value: targetValue } = {} } = option;

        const fieldValue = !value ? targetValue : option.value;
        this.fieldRef.value = fieldValue;

        if (onChange) {
            onChange(fieldValue);
        }
    }

    containerProps() {
        const {
            attr: {
                autoComplete,
                autocomplete,
                noPlaceholder,
                selectPlaceholder,
                ...attr
            } = {},
            events,
            setRef,
            isDisabled
        } = this.props;

        const { isExpanded } = this.state;

        return {
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete
            },
            events,
            setRef,
            isDisabled,
            isExpanded,
            options: this.getOptions()
        };
    }
}

export default FieldSelectContainer;
