import __ from '@scandipwa/webpack-i18n-runtime/src/util/__';

import { CUSTOMER_BILLING_ADDRESS, CUSTOMER_SHIPPING_ADDRESS }
from 'Component/CheckoutAddressForm/CheckoutAddressForm.config';
import Link from 'Component/Link';
import {
    CheckoutSuccess as SourceCheckoutSuccess
} from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

import './CheckoutSuccess.style';

/** @namespace Bodypwa/Component/CheckoutSuccess/Component */
export class CheckoutSuccessComponent extends SourceCheckoutSuccess {
    renderButtons() {
        return (
            <>
                <div block="CheckoutSuccess" elem="ButtonWrapper">
                    <Link
                      block="Button"
                      mix={ { block: 'CheckoutSuccess', elem: 'OrdersAccount' } }
                      to="/my-account/my-orders"
                    >
                        { __('See order status') }
                    </Link>
                </div>
                <div block="CheckoutSuccess" elem="ButtonWrapper">
                    <Link
                      block="Button"
                      mods={ { isHollow: true } }
                      mix={ { block: 'CheckoutSuccess', elem: 'ContinueButton' } }
                      to="/"
                    >
                        { __('Back to shopping') }
                    </Link>
                </div>
            </>
        );
    }

    renderAditonalContent() {
        return 'asdasdasdasdas';
    }

    render() {
        const { orderID, resetCheckout } = this.props;
        // setIsCheckout();
        BrowserDatabase.deleteItem(CUSTOMER_SHIPPING_ADDRESS);
        BrowserDatabase.deleteItem(CUSTOMER_BILLING_ADDRESS);
        resetCheckout();
        return (
            <div block="CheckoutSuccess">
                <p
                  block="CheckoutSuccess"
                  elem="Subtitle"
                >
                    { __('Your order # is:') }
                    <strong>{ orderID || '' }</strong>
                </p>
                <p
                  block="CheckoutSuccess"
                  elem="Description"
                >
                      { __('We`ll email you an order confirmation with details and tracking info.') }

                </p>
                { this.renderButtons() }
            </div>
        );
    }
}

export default CheckoutSuccessComponent;
