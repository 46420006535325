import { AMOUNT_OF_PLACEHOLDERS } from '@scandipwa/scandipwa/src/component/SearchOverlay/SearchOverlay.config';

import SearchCategory from 'Component/SearchCategory';
import {
    SearchOverlay as SourceSearchOverlay
} from 'SourceComponent/SearchOverlay/SearchOverlay.component';

import './SearchOverlay.style';

/** @namespace Bodypwa/Component/SearchOverlay/Component */
export class SearchOverlayComponent extends SourceSearchOverlay {
    renderSearchCategory(category, i) {
        const { clearSearchResults, deactivateSearchBar } = this.props;

        return (
            <SearchCategory
              category={ category }
              clearSearchResults={ clearSearchResults }
              deactivateSearchBar={ deactivateSearchBar }
              key={ i }
            />
        );
    }

    renderSearchResults() {
        const {
            searchResults,
            totalResults,
            isLoading,
            seeAllResults,
            updateIsUrlRedirectLoading,
            isUrlRedirectLoading,
            redirectUrl
        } = this.props;

        if (!searchResults.length && !isLoading && !this.timeout) {
            updateIsUrlRedirectLoading(false);
            return this.renderNoResults();
        }

        if (isUrlRedirectLoading) {
            return null;
        }

        const resultsToRender = (isLoading || this.timeout) ? Array(AMOUNT_OF_PLACEHOLDERS).fill({}) : searchResults;

        // eslint-disable-next-line max-len,no-magic-numbers
        const categoriesToRender = searchResults.map((result) => result.categories).flat().filter((category) => (category.level === 4));
        const ids = categoriesToRender.map((o) => o.uid);
        // eslint-disable-next-line max-len
        const filteredCategories = categoriesToRender.filter(({ uid }, index) => !ids.includes(uid, index + 1));

        const viewAllLabel = redirectUrl ? __('View all results') : __('View all %s', totalResults);
        return (
            <div
              block="SearchOverlay"
              elem="Scrollable"
            >
                <h4 className="SearchProducts-Headline">{ __('Products') }</h4>
                <ul className="SearchProducts">
                    { resultsToRender.map((item, i) => this.renderSearchItem(item, i)) }
                </ul>
                <button
                  block="Button"
                  onClick={ seeAllResults }
                >
                    { viewAllLabel }
                </button>
                <h4 className="SearchCategories-Headline">{ __('Categories') }</h4>
                <ul className="SearchCategories">
                    { filteredCategories.map((item, i) => this.renderSearchCategory(item, i)) }
                </ul>
            </div>
        );
    }
}

export default SearchOverlayComponent;
