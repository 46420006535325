import {
    ChevronIcon as SourceChevronIcon
} from 'SourceComponent/ChevronIcon/ChevronIcon.component';

import './ChevronIcon.style';
/** @namespace Bodypwa/Component/ChevronIcon/Component */
export class ChevronIconComponent extends SourceChevronIcon {
    render() {
        const { direction } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width="18"
              height="13"
              viewBox="0 0 18 13"
              xmlns="http://www.w3.org/2000/svg"
            >
                { /* eslint-disable-next-line max-len */ }
                <path d="M17.4822 0.460016L8.98221 8.95999L0.482178 0.459961L0.482178 3.9955L8.98221 12.4955L17.4822 3.99555V0.460016Z" />
            </svg>

        );
    }
}

export default ChevronIconComponent;
