import { createRef } from 'react';
import { connect } from 'react-redux';

import {
    SELECTEBALE_ATTRIBUTES_ARRAY
} from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import componentLoader from 'Util/componentLoader';
import history from 'Util/History';
import { appendWithStoreCode, objectToUri } from 'Util/Url';

export const CartDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
), 2);

export const ProductsVariantsDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductsVariants/ProductsVariants.dispatcher'
), 2);
/** @namespace Bodypwa/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    requestProductListVariants: (options) => ProductsVariantsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    )
});

/** @namespace Bodypwa/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    categoryId: state.ProductListReducer.id,
    pages: state.ProductListReducer.pages,
    categoryPage: state.ProductListReducer.categoryPage
});
/** @namespace Bodypwa/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    productRef = createRef();

    onProductClick() {
        const { categoryId } = this.props;
        // Original values
        const originalScrollY = document.documentElement.scrollTop;
        const originalPageHeight = document.documentElement.scrollHeight;

        BrowserDatabase.setItem({ originalScrollY, originalPageHeight, categoryId }, 'scroll-position');
    }

    containerProps() {
        const {
            isSwiper,
            variantsPage,
            pages,
            categoryPage,
            categoryId,
            requestProductListVariants
        } = this.props;

        return {
            ...super.containerProps(),
            isSwiper,
            variantsPage,
            pages,
            categoryPage,
            categoryId,
            requestProductListVariants,
            productRef: this.productRef,
            onProductClick: this.onProductClick.bind(this)
        };
    }

    getLinkTo() {
        const {
            baseLinkUrl,
            productUsesCategories,
            categoryUrlSuffix,
            product: {
                url, url_rewrites = [], configurable_options
            },
            product
        } = this.props;
        const { pathname: storePrefix } = new URL(baseLinkUrl || window.location.origin);
        const { location: { pathname } } = history;

        if (!url) {
            return undefined;
        }

        const { parameters } = this.state;
        const { state: { category = null } = {} } = history.location;
        const categoryUrlPart = pathname.replace(storePrefix, '').replace(categoryUrlSuffix, '');
        const productUrl = `${categoryUrlPart}/${url.replace(storePrefix, '')}`;

        // if 'Product Use Categories' is enabled then use the current window location to see if the product
        // has any url_rewrite for that path. (if not then just use the default url)
        const rewriteUrl = url_rewrites.find(({ url }) => url.includes(productUrl)) || {};

        const rewriteUrlPath = productUsesCategories
            ? (rewriteUrl.url && appendWithStoreCode(rewriteUrl.url)) || url
            : url;

        const configurableOptions = Object.keys(configurable_options).filter(
            (key) => SELECTEBALE_ATTRIBUTES_ARRAY.includes(key)
        );
        // eslint-disable-next-line no-unused-vars
        const { small_image, ...rest } = product;
        return {
            pathname: rewriteUrlPath,
            state: { product: rest, prevCategoryId: category },
            search: objectToUri(Object.fromEntries(Object.entries(parameters)
                // eslint-disable-next-line no-unused-vars
                .filter(([key, value]) => value !== undefined
                && configurableOptions[0] === key)))
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
