/* eslint-disable max-len */
import { UserIcon as SourceUserIcon } from 'SourceComponent/UserIcon/UserIcon.component';

/** @namespace Bodypwa/Component/UserIcon/Component */

export class UserIconComponent extends SourceUserIcon {
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="UserIcon"
              mods={ { isActive } }
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <path fillRule="evenodd" clipRule="evenodd" d="M10 2.5C7.23858 2.5 5 4.73858 5 7.5C5 10.2614 7.23858 12.5 10 12.5C12.7614 12.5 15 10.2614 15 7.5C15 4.73858 12.7614 2.5 10 2.5ZM2.5 7.5C2.5 3.35786 5.85786 0 10 0C14.1421 0 17.5 3.35786 17.5 7.5C17.5 11.6421 14.1421 15 10 15C5.85786 15 2.5 11.6421 2.5 7.5ZM5.44993 16.25H14.5501L20 17.8071V20H17.5V19.6929L14.1999 18.75H5.80007L2.5 19.6929V20H0L0 17.8071L5.44993 16.25Z" />
            </svg>
        );
    }
}

export default UserIconComponent;
